<template>
  <c-box
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#FFF', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['auto', '74vh']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
          isCurrentStep: true,
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />

    <BaseText
      size-mobile="18px"
      size-desktop="28px"
      text-align="center"
    >
      Periksa Pesanan
    </BaseText>

    <c-box
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['0 12px', '30px']"
      :margin-top="['22px', '20px']"
    >
      <ElementCheckOrderInfoProgram
        :route-change-program="{
          name: 'client.select-program',
          query: $route.query,
        }"
        :photo-url="productImage"
        :name=" productName"
        :sub-name="productServiceName + ' ' + '('+ duration +')'"
        :more-info-url="handleShowDetailProgram"
        :price="subTotalPrice"
        :coupon-status="statusAppliedCoupon"
        :is-applying-coupon="isLoadingApplyCoupon"
        :coupon-code="couponCode"
        @set-coupon-code="(e) => {
          couponCode = e.target.value
          couponDetail.isValid = null
        }"
        @handle-click-check-coupon="handleApplyCoupon"
        :has-phone-number="hasPhoneNumber"
        :is-valid-phone="isValidPhone"
        :phone="phone"
        @set-phone="(e) => {
          phone = e.target.value
          isValidPhone = true
        }"
        :coupon-detail="couponDetail"
      />

      <c-box margin-top="20px">
        <ElementCheckOrderInfoPrice
          :price="subTotalPrice"
          :discount="discount"
          :final-price="totalPrice"
        />
      </c-box>
    </c-box>

    <c-flex
      :padding="['0 12px', '0']"
      :gap="['4px', '16px']"
      :margin-top="['16px', '20px']"
    >
      <BaseButton
        size="large"
        variant="outlined"
        w="100%"
        border-radius="1000px"
        @click="handlePrevious"
      >
        Kembali
      </BaseButton>
      <BaseButton
        size="large"
        variant="contained"
        w="100%"
        border-radius="1000px"
        :is-loading="isLoadingCheckout"
        :disabled="!formState.success"
        @click="handleNext"
      >
        Selanjutnya
      </BaseButton>
    </c-flex>

    <ModalCouponInvalid
      :is-open="isOpenModalCouponInvalid"
      @handleClose="isOpenModalCouponInvalid = false"
    />

    <ModalInfo
      for="errorCannotPurchaseDifferentProgram"
      :with-button-close="false"
      :is-open="isOpenModalErrorProgramCannotPurchase"
      @close="close"
    />
    
    <ModalErrorProgramCannotPurchaseKonsultation
      :is-open="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation"
      @handleClose="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false"
    />
  </c-box>
</template>

<script>
import Stepper from '@/components/elements/stepper.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import { formatCurrency } from '@/utils/format-currency'
import ModalCouponInvalid from '@/views/client/cart/check-order/_widgets/modal-coupon-invalid.vue'
import ModalInfo from '@/components/widgets/modal-info/index.vue'
import ModalErrorProgramCannotPurchaseKonsultation
  from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase-konsultation.vue'
import ElementCheckOrderInfoProgram from '@/components/element-check-order/element-check-order-info-program.vue'
import ElementCheckOrderInfoPrice from '@/components/element-check-order/element-check-order-info-price.vue'
import { z } from 'zod'

export default {
  name: 'Buy3Page',
  components: {
    ElementCheckOrderInfoPrice,
    ElementCheckOrderInfoProgram,
    ModalInfo,
    ModalCouponInvalid,
    BaseButton,
    BaseText,
    Stepper,
    ModalErrorProgramCannotPurchaseKonsultation,
  },
  data() {
    return {
      isOpenModalCouponInvalid: false,
      isOpenModalErrorProgramCannotPurchase: false,
      isLoadingApplyCoupon: false,
      isLoadingCheckout: false,
      couponCode: '',
      productServiceId: this.$route.query.product_service_id,
      productImage: '',
      productName: '...',
      productServiceName: '...',
      duration: '...',
      subTotalPrice: 0,
      couponDetail: {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
        isSkipPayment: 0,
      },
      isModalOpen: {
        ModalErrorProgramCannotPurchaseKonsultation: false,
      },
      product: null,
      hasPhoneNumber: false,
      isValidPhone: true,
      phone: '',
      dirtyField: { 
        phone: false,
        couponCode: false,
      },
      isGoogle: false,
    }
  },
  validations: {
    couponCode: { required },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      selectedCart: 'cart/selectedCart',
      userType: 'userType/userType',
    }),
    formState() {
      if (!this.isGoogle && this.hasPhoneNumber) return { success: true }
      const user = z.object({
        phone: z.string().min(1).max(13),
        couponCode: z.string().nullable(),
      })
      return user.safeParse({
        phone: this.phone,
        couponCode: this.couponCode,
      })
    },
    handleShowDetailProgram() {
      if (this.product?.isKonsultasi === 1) {
        return {
          name: 'client.program.consultation',
        }
      }
      return {
        name: 'client.select-program',
        query: this.$route.query,
      }
    },
    statusAppliedCoupon() {
      if (this.couponDetail.isValid === null) {
        return 'not-applied'
      } else if (this.couponCode && this.couponDetail.isValid) {
        return 'success'
      } else if (!(this.couponCode && this.couponDetail.isValid)) {
        return 'failed'
      }
      return 'not-applied'
    },
    discount() {
      if (this.couponDetail.type === 'percent') {
        return this.subTotalPrice * (this.couponDetail.totalDiscount / 100)
      }
      return this.couponDetail.totalDiscount
    },
    totalPrice() {
      return this.subTotalPrice - this.discount
    },
  },
  watch: {
    userType: {
      handler(newValue, oldValue) {
        if (newValue && (newValue != '-') && (newValue != oldValue)) {
          // POSTHOG capture `plan purchased`
          this.$posthog.capture('plan purchased', {
            userType: this.userType ?? 'not-registered',
            program: {
              id: this.product?.productId,
              name: this.product?.product?.name,
              productService: {
                id: this.product?.id,
                name: this.product?.name,
                programsService: this.product?.programsService,
                duration: this.product?.duration,
                price: this.product?.price,
              },
            },
          })
        }
      },
    }, 
  },
  async mounted() {
    if (!this.isAuthenticated) {
      this.handlePrevious()
      return
    }
    await this.init()
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapActions({
      getProductServiceId: 'general/getProductServiceId',
      reqCheckout: 'clients/reqCheckout',
      reqCheckCoupon: 'clients/reqCheckCoupon',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
      getProfileClient: 'profile/getProfileClient',
      updateProfile: 'profile/updateProfileClient',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    formatCurrency,
    handlePrevious() {
      if (this.isAuthenticated) {
        this.$router.back()
        return
      }
      this.$router.push({
        name: 'client.select-program',
        query: this.$route.query,
      })
    },
    async handleNext() {
      try {
        this.isLoadingCheckout = true
        const resUpdate = await this.updateProfile({
          profile: {
            phone: this.phone,
          },
        })
        if (resUpdate.status === false) {
          this.isValidPhone = false
          return
        }

        const res = await this.reqCheckout({
          productServiceId: this.productServiceId,
          couponId: this.couponDetail?.id,
          qty: 1,
        })

        // POSTHOG capture `order created`
        const selectedProgram = {
          productService: this.product,
          coupon: this.couponDetail.isValid ? this.couponDetail : null,
          qty: 1,
        }
        this.$posthog.capture('order created', {
          userType: this.userType ?? 'not-registered',
          program: selectedProgram,
        })
        window.fbq('track', 'CheckOut')
        
        if (res?.data?.isSkipPayment) {
          return this.$router.replace({
            name: 'client.buy-program.step-3',
            query: { transaction_id: res?.data?.id },
          }).catch(() => {})
        }
        this.$router.push({
          name: 'client.buy-program.payment',
          query: { transaction_id: res.data?.id },
        })
      } catch (e) {
        if (this.product?.isKonsultasi === 1) {
          this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = true
          return
        } else if (parseErrorCatch(e).startsWith('cannot checkout, active program found :')) {
          this.isOpenModalErrorProgramCannotPurchase = true
          return
        }
      } finally {
        this.isLoadingCheckout = false
      }
    },
    resetCoupon() {
      this.couponCode = ''
      this.couponDetail = {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
        isSkipPayment: 0,
      }
    },
    async handleApplyCoupon() {
      if (this.couponDetail.isValid) {
        this.resetCoupon()
        return
      }
      try {
        this.isLoadingApplyCoupon = true
        const res = await this.reqCheckCoupon({
          couponCode: this.couponCode,
          productServiceId: this.productServiceId,
        })
        this.couponDetail = res.data
        this.couponDetail.isValid = true
      } catch (e) {
        this.isOpenModalCouponInvalid = true
        this.couponDetail.isValid = false
      } finally {
        this.isLoadingApplyCoupon = false
      }
    },
    close() {
      this.isOpenModalErrorProgramCannotPurchase = false
    },
    async init() {
      try {
        const profile = await this.getProfileClient()
        this.hasPhoneNumber = !!profile?.phone
        this.phone = profile?.phone
        this.isGoogle = !!profile?.isGoogle

        if (!this.productServiceId) {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch({ message: 'ERR_NO_PRODUCT_SERVICE_ID' }),
            duration: 3000,
          })
          this.$router.push({ name: 'client.select-program' })
        }
        const res = await this.getProductServiceId(this.productServiceId)
        this.productImage = res.data?.product?.photoUrl || ''
        this.productName = res.data?.product?.name || ''
        this.productServiceName = res.data?.programsService || ''
        this.duration = res.data?.duration ? `${res.data?.duration} hari` : ''
        this.subTotalPrice = res.data?.price || ''
        this.product = res.data
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
        this.$router.push('/')
      }
    },
  },
}
</script>
